<template>
  <div class="wrapper">
    <div class="banner">
      <img src="../../assets/img/finance_2/banner.png" alt="" />
    </div>
    <!-- <div class="breadcrumb_box">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>{{ breadcrumb }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div> -->
    <div class="container">
      <div class="box">
        <div class="list">
          <div class="item" v-for="item in dataList" :key="item.WZID">
            <div class="left_img">
              <img src="../../assets/img/activity/new.png" v-if="wzid == 1" alt="" />
              <img src="../../assets/img/activity/zc.png" v-if="wzid == 2" alt="" />
            </div>
            <div class="right_content">
              <div class="top">
                <div class="title">{{ item.TITLE }}</div>
                <div class="date">{{ item.FBSJ }}</div>
              </div>
              <div class="center">
                <p>
                  {{ item.DETAILTITLE }}
                </p>
              </div>
              <div class="btn" @click="viewDetail(item.WZID, item.TITLE)">
                查看详情
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="page_box">
          <el-pagination background layout="prev, pager, next" :total="100">
          </el-pagination>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      wzid: 0,
      breadcrumb: "最新活动",
      dataList: [],
      activityList: [
        {
          SFXSSJ: "Y",
          DETAILTITLE:
            "全国税务系统新时代“枫桥式”税务所（分局、办税服务厅）建设交流推进会在京召开",
          DQZT: "1",
          LMDM: 2641,
          INDEXNO: "11140000012184142Y/2023-00823",
          SFZCJD: "N",
          FBSJ: "2023-12-04 10:41:05",
          LMMC: "税务新闻",
          YXYJFK: "N",
          WZID: 1785162,
          WZLY: "国家税务总局办公厅",
          TITLE:
            "全国税务系统新时代“枫桥式”税务所（分局、办税服务厅）建设交流推进会在京召开",
          SFGQ: "N",
          RN: 1,
        },
        {
          SFXSSJ: "Y",
          DETAILTITLE:
            "社保缴费出实招 惠企利民见实效 税务部门深入开展主题教育推动社保缴费更便捷更高效",
          DQZT: "1",
          LMDM: 2641,
          INDEXNO: "11140000012184142Y/2023-00822",
          SFZCJD: "N",
          FBSJ: "2023-12-01 16:45:29",
          LMMC: "税务新闻",
          YXYJFK: "N",
          WZID: 1785136,
          WZLY: "国家税务总局办公厅",
          XQYTITLE:
            '<p style="text-align: center;"><span style="font-size: 28px;">社保缴费出实招 惠企利民见实效</span></p><p style="text-align: center;"><span style="font-size: 22px;">税务部门深入开展主题教育推动社保缴费更便捷更高效</span></p>',
          TITLE:
            "社保缴费出实招 惠企利民见实效 税务部门深入开展主题教育推动社保缴费更便捷更高效",
          SFGQ: "N",
          RN: 2,
        },
        {
          SFXSSJ: "Y",
          DETAILTITLE: "税费服务有力度 惠企便民有温度",
          DQZT: "1",
          LMDM: 2641,
          INDEXNO: "11140000012184142Y/2023-00821",
          SFZCJD: "N",
          FBSJ: "2023-12-01 14:54:10",
          LMMC: "税务新闻",
          YXYJFK: "N",
          WZID: 1785113,
          WZLY: "央广网客户端",
          TITLE: "税费服务有力度 惠企便民有温度",
          SFGQ: "N",
          RN: 3,
        },
        {
          SFXSSJ: "Y",
          DETAILTITLE: "胡静林同志任国家税务总局党委书记",
          DQZT: "1",
          LMDM: 2641,
          INDEXNO: "11140000012184142Y/2023-00818",
          SFZCJD: "N",
          FBSJ: "2023-11-28 11:06:36",
          LMMC: "税务新闻",
          YXYJFK: "N",
          WZID: 1784903,
          WZLY: "国家税务总局办公厅",
          TITLE: "胡静林同志任国家税务总局党委书记",
          SFGQ: "N",
          RN: 4,
        },
        {
          SFXSSJ: "Y",
          DETAILTITLE:
            "税费优惠助经营主体稳信心 激活力 增动能 今年1-10月全国新增减税降费及退税缓费超1.6万亿元",
          DQZT: "1",
          LMDM: 2641,
          INDEXNO: "11140000012184142Y/2023-00816",
          SFZCJD: "N",
          FBSJ: "2023-11-27 17:23:07",
          LMMC: "税务新闻",
          YXYJFK: "N",
          WZID: 1784864,
          WZLY: "国家税务总局办公厅",
          XQYTITLE:
            '<p style="text-align: center;"><span style="font-size: 28px;">税费优惠助经营主体稳信心 激活力 增动能</span></p><p style="text-align: center;"><span style="font-size: 22px;">今年1-10月全国新增减税降费及退税缓费超1.6万亿元</span></p>',
          TITLE:
            "税费优惠助经营主体稳信心 激活力 增动能 今年1-10月全国新增减税降费及退税缓费超1.6万亿元",
          SFGQ: "N",
          RN: 5,
        },
        {
          SFXSSJ: "Y",
          DETAILTITLE: "晋中税务：税惠助力企业绘就发展新蓝图",
          DQZT: "1",
          LMDM: 2462,
          SFZCJD: "N",
          FBSJ: "2023-12-04 14:54:04",
          LMMC: "基层动态",
          YXYJFK: "N",
          WZID: 1785179,
          WZLY: "国家税务总局山西省税务局",
          TITLE: "晋中税务：税惠助力企业绘就发展新蓝图",
          SFGQ: "N",
          RN: 1,
        },
        {
          SFXSSJ: "Y",
          DETAILTITLE: "忻州税务：“暖心”服务助力企业“舒心”发展",
          DQZT: "1",
          LMDM: 2462,
          SFZCJD: "N",
          FBSJ: "2023-12-01 14:54:22",
          LMMC: "基层动态",
          YXYJFK: "N",
          WZID: 1785112,
          WZLY: "国家税务总局山西省税务局",
          TITLE: "忻州税务：“暖心”服务助力企业“舒心”发展",
          SFGQ: "N",
          RN: 2,
        },
        {
          SFXSSJ: "Y",
          DETAILTITLE: "长治屯留区税务：“政策找人”精准推 定制服务“安心享”",
          DQZT: "1",
          LMDM: 2462,
          SFZCJD: "N",
          FBSJ: "2023-11-27 16:23:52",
          LMMC: "基层动态",
          YXYJFK: "N",
          WZID: 1784840,
          WZLY: "国家税务总局山西省税务局",
          TITLE: "长治屯留区税务：“政策找人”精准推 定制服务“安心享”",
          SFGQ: "N",
          RN: 3,
        },
        {
          SFXSSJ: "Y",
          DETAILTITLE: "太原娄烦税务：助力特色产业 服务乡村振兴",
          DQZT: "1",
          LMDM: 2462,
          SFZCJD: "N",
          FBSJ: "2023-11-24 14:55:27",
          LMMC: "基层动态",
          YXYJFK: "N",
          WZID: 1784747,
          WZLY: "国家税务总局山西省税务局",
          TITLE: "太原娄烦税务：助力特色产业 服务乡村振兴",
          SFGQ: "N",
          RN: 4,
        },
        {
          SFXSSJ: "Y",
          DETAILTITLE: "忻州偏关税务：税惠“添柴” 糜子产业旺起来",
          DQZT: "1",
          LMDM: 2462,
          SFZCJD: "N",
          FBSJ: "2023-11-22 15:21:30",
          LMMC: "基层动态",
          YXYJFK: "N",
          WZID: 1784602,
          WZLY: "国家税务总局山西省税务局",
          TITLE: "忻州偏关税务：税惠“添柴” 糜子产业旺起来",
          SFGQ: "N",
          RN: 5,
        },
        {
          SFXSSJ: "Y",
          DETAILTITLE: "运城税务：税惠添彩 瓜果飘香绘“丰”景",
          DQZT: "1",
          LMDM: 2462,
          SFZCJD: "N",
          FBSJ: "2023-11-20 16:28:56",
          LMMC: "基层动态",
          YXYJFK: "N",
          WZID: 1784500,
          WZLY: "国家税务总局山西省税务局",
          TITLE: "运城税务：税惠添彩 瓜果飘香绘“丰”景",
          SFGQ: "N",
          RN: 6,
        },
        {
          SFXSSJ: "Y",
          DETAILTITLE: "吕梁税务：“税周到”服务为民营经济赋能添力",
          DQZT: "1",
          LMDM: 2462,
          SFZCJD: "N",
          FBSJ: "2023-11-17 15:59:32",
          LMMC: "基层动态",
          YXYJFK: "N",
          WZID: 1784424,
          WZLY: "国家税务总局山西省税务局",
          TITLE: "吕梁税务：“税周到”服务为民营经济赋能添力",
          SFGQ: "N",
          RN: 7,
        },
        {
          SFXSSJ: "Y",
          DETAILTITLE: "晋中税务：“数据支撑”铺路 “政策找人”精准",
          DQZT: "1",
          LMDM: 2462,
          SFZCJD: "N",
          FBSJ: "2023-11-13 10:06:55",
          LMMC: "基层动态",
          YXYJFK: "N",
          WZID: 1784209,
          WZLY: "国家税务总局山西省税务局",
          TITLE: "晋中税务：“数据支撑”铺路 “政策找人”精准",
          SFGQ: "N",
          RN: 8,
        },
      ],
      ggzcList: [
        {
          SFXSSJ: "Y",
          DETAILTITLE:
            "国家税务总局 工业和信息化部关于发布《免征车辆购置税的设有固定装置的非运输专用作业车辆目录》（第十二批）的公告",
          DQZT: "1",
          FBLX: "1 ",
          LMDM: 545,
          INDEXNO: "11140000012184142Y/2023-00817",
          SFZCJD: "N",
          FBSJ: "2023-11-20 00:00:00",
          LMMC: "最新文件",
          YXYJFK: "N",
          WZID: 1784897,
          WZLY: "国家税务总局 工业和信息化部",
          TITLE:
            "国家税务总局 工业和信息化部关于发布《免征车辆购置税的设有固定装置的非运输专用作业车辆目录》（第十二批）的公告",
          SFGQ: "N",
          RN: 1,
          TYPE: "180578",
        },
        {
          SFXSSJ: "Y",
          DETAILTITLE:
            "国家税务总局山西省税务局 山西省财政厅 山西省人力资源和社会保障厅 中国人民银行山西省分行 山西省医疗保障局关于优化调整社会保险费申报缴纳流程的公告",
          DQZT: "1",
          FBLX: "1 ",
          LMDM: 545,
          INDEXNO: "11140000012184142Y/2023-00788",
          SFZCJD: "N",
          FBSJ: "2023-11-17 18:02:30",
          LMMC: "最新文件",
          YXYJFK: "N",
          WZID: 1784441,
          WZLY: "国家税务总局山西省税务局 山西省财政厅 山西省人力资源和社会保障厅 中国人民银行山西省分行 山西省医疗保障局",
          TITLE:
            "国家税务总局山西省税务局 山西省财政厅 山西省人力资源和社会保障厅 中国人民银行山西省分行 山西省医疗保障局关于优化调整社会保险费申报缴纳流程的公告",
          SFGQ: "N",
          RN: 2,
          TYPE: "180579",
        },
        {
          SFXSSJ: "Y",
          DETAILTITLE: "税务总局更新发布“非接触式”办税缴费事项清单",
          DQZT: "1",
          FBLX: "1 ",
          LMDM: 545,
          INDEXNO: "11140000012184142Y/2023-00779",
          SFZCJD: "N",
          FBSJ: "2023-11-15 10:22:30",
          LMMC: "最新文件",
          YXYJFK: "N",
          WZID: 1784320,
          WZLY: "国家税务总局",
          TITLE: "税务总局更新发布“非接触式”办税缴费事项清单",
          SFGQ: "N",
          RN: 3,
          TYPE: "180578",
        },
        {
          SFXSSJ: "Y",
          DETAILTITLE:
            "财政部 税务总局关于确认中国红十字会总会等群众团体2024年度—2026年度公益性捐赠税前扣除资格的公告",
          DQZT: "1",
          FBLX: "1 ",
          LMDM: 545,
          INDEXNO: "11140000012184142Y/2023-00811",
          SFZCJD: "N",
          FBSJ: "2023-11-13 00:00:00",
          LMMC: "最新文件",
          YXYJFK: "N",
          WZID: 1784647,
          WZLY: "财政部 税务总局",
          TITLE:
            "财政部 税务总局关于确认中国红十字会总会等群众团体2024年度—2026年度公益性捐赠税前扣除资格的公告",
          SFGQ: "N",
          RN: 4,
          TYPE: "180578",
        },
        {
          SFXSSJ: "Y",
          DETAILTITLE:
            "山西省财政厅 国家税务总局山西省税务局关于明确山西省非营利组织免税资格认定管理有关问题的通知",
          DQZT: "1",
          FBLX: "1 ",
          LMDM: 545,
          INDEXNO: "11140000012184142Y/2023-00783",
          SFZCJD: "N",
          FBSJ: "2023-11-08 00:00:00",
          LMMC: "最新文件",
          YXYJFK: "N",
          WZID: 1784411,
          WZLY: "山西省财政厅 国家税务总局山西省税务局",
          TITLE:
            "山西省财政厅 国家税务总局山西省税务局关于明确山西省非营利组织免税资格认定管理有关问题的通知",
          SFGQ: "N",
          RN: 5,
          TYPE: "180579",
        },
        {
          SFXSSJ: "Y",
          DETAILTITLE:
            "财政部 海关总署 税务总局关于国家综合性消防救援队伍进口税收政策的通知",
          DQZT: "1",
          FBLX: "1 ",
          LMDM: 545,
          INDEXNO: "11140000012184142Y/2023-00738",
          SFZCJD: "N",
          FBSJ: "2023-10-23 00:00:00",
          LMMC: "最新文件",
          YXYJFK: "N",
          WZID: 1783525,
          WZLY: "财政部 海关总署 税务总局",
          TITLE:
            "财政部 海关总署 税务总局关于国家综合性消防救援队伍进口税收政策的通知",
          SFGQ: "N",
          RN: 6,
          TYPE: "180578",
        },
        {
          SFXSSJ: "Y",
          DETAILTITLE:
            "财政部 税务总局 中央宣传部关于延续实施文化体制改革中经营性文化事业单位转制为企业有关税收政策的公告",
          DQZT: "1",
          FBLX: "1 ",
          LMDM: 545,
          INDEXNO: "11140000012184142Y/2023-00733",
          SFZCJD: "N",
          FBSJ: "2023-10-23 00:00:00",
          LMMC: "最新文件",
          YXYJFK: "N",
          WZID: 1783448,
          WZLY: "财政部 税务总局 中央宣传部",
          TITLE:
            "财政部 税务总局 中央宣传部关于延续实施文化体制改革中经营性文化事业单位转制为企业有关税收政策的公告",
          SFGQ: "N",
          RN: 7,
          TYPE: "180578",
        },
        {
          SFXSSJ: "Y",
          DETAILTITLE:
            "财政部 税务总局关于更新中外合作油（气）田开采企业名单的通知",
          DQZT: "1",
          FBLX: "1 ",
          LMDM: 545,
          INDEXNO: "11140000012184142Y/2023-00691",
          SFZCJD: "N",
          FBSJ: "2023-10-07 00:00:00",
          LMMC: "最新文件",
          YXYJFK: "N",
          WZID: 1782798,
          WZLY: "财政部 税务总局",
          TITLE: "财政部 税务总局关于更新中外合作油（气）田开采企业名单的通知",
          SFGQ: "N",
          RN: 8,
          TYPE: "180578",
        },
        {
          SFXSSJ: "Y",
          DETAILTITLE:
            "国家税务总局关于第九批全国税务领军人才学员选拔预录取考察的通告",
          DQZT: "1",
          FBLX: "1 ",
          LMDM: 545,
          INDEXNO: "11140000012184142Y/2023-00686",
          SFZCJD: "N",
          FBSJ: "2023-09-28 00:00:00",
          LMMC: "最新文件",
          YXYJFK: "N",
          WZID: 1782455,
          WZLY: "国家税务总局",
          TITLE:
            "国家税务总局关于第九批全国税务领军人才学员选拔预录取考察的通告",
          SFGQ: "N",
          RN: 9,
          TYPE: "180578",
        },
        {
          SFXSSJ: "Y",
          DETAILTITLE:
            "财政部 税务总局 住房城乡建设部关于保障性住房有关税费政策的公告",
          DQZT: "1",
          FBLX: "1 ",
          LMDM: 545,
          INDEXNO: "11140000012184142Y/2023-00685",
          SFZCJD: "N",
          FBSJ: "2023-09-28 00:00:00",
          LMMC: "最新文件",
          YXYJFK: "N",
          WZID: 1782453,
          WZLY: "财政部 税务总局 住房城乡建设部",
          TITLE:
            "财政部 税务总局 住房城乡建设部关于保障性住房有关税费政策的公告",
          SFGQ: "N",
          RN: 10,
          TYPE: "180578",
        },
        {
          SFXSSJ: "Y",
          DETAILTITLE:
            "财政部 税务总局关于继续对废矿物油再生油品免征消费税的公告",
          DQZT: "1",
          FBLX: "1 ",
          LMDM: 545,
          INDEXNO: "11140000012184142Y/2023-00680",
          SFZCJD: "N",
          FBSJ: "2023-09-27 00:00:00",
          LMMC: "最新文件",
          YXYJFK: "N",
          WZID: 1782451,
          WZLY: "财政部 税务总局",
          TITLE: "财政部 税务总局关于继续对废矿物油再生油品免征消费税的公告",
          SFGQ: "N",
          RN: 11,
          TYPE: "180578",
        },
      ],
    };
  },
  watch: {},
  computed: {},
  methods: {
    viewDetail(id, title) {
      sessionStorage.setItem("hdtitle", title);
      if (this.wzid == 1) {
        this.$router.push({
          path: "/activity/detail_hd",
          query: { id: id },
        });
      }
      if (this.wzid == 2) {
        this.$router.push({
          path: "/activity/detail_zc",
          query: { id: id },
        });
      }
    },
  },
  created() {
    let id = this.$route.query.id;
    this.wzid = this.$route.query.id;
    if (id == 1) {
      this.breadcrumb = "最新活动";
      this.dataList = this.activityList;
    }
    if (id == 2) {
      this.breadcrumb = "公共政策";
      this.dataList = this.ggzcList;
    }
  },
  mounted() {},
};
</script>
<style scoped>
.wrapper {
}
.banner {
  width: 100%;
  height: 300px;
}

.banner img {
  display: block;
  width: 100%;
  height: 100%;
}

.container {
  width: 100%;
  padding: 40px;
}

.breadcrumb_box {
  width: 1200px;
  padding: 30px 0 0;
  margin: 0 auto;
}

.box {
  width: 1200px;
  padding: 30px 0;
  margin: 0 auto;
}

.item {
  display: flex;
  margin-bottom: 30px;
}

.left_img {
  width: 360px;
  height: 200px;
  border-radius: 10px;
  overflow: hidden;
}

.left_img img {
  display: block;
  width: 100%;
  height: 100%;
}

.right_content {
  flex: 1;
  width: 840px;
  padding: 10px 30px;
}

.top {
  width: 100%;
}

.title {
  font-size: 18px;
  font-weight: 600;
  color: #333;
}

.date {
  font-size: 12px;
  color: #999;
  margin-top: 10px;
}

.center {
  padding-top: 20px;
  margin-top: 10px;
  border-top: 1px solid #f5f5f5;
}

.center p {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.btn {
  margin-top: 30px;
  width: 80px;
  height: 32px;
  line-height: 32px;
  font-size: 14px;
  color: #055afe;
  border: 1px solid #055afe;
  text-align: center;
  cursor: pointer;
  border-radius: 25px;
}

.page_box {
  margin-top: 40px;
  text-align: right;
}
</style>
